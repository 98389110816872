<template>
  <Teleport to="body">
    <div
      class="fixed inset-0 z-50 flex items-center justify-center bg-slate-500/90"
      @click.self="closeReplayModal"
    >
      <iframe :src="replayModalUrl" class="w-full h-full"/>
    </div>
  </Teleport>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

const uiStore = useUiStore();
const { replayModalUrl, } = storeToRefs(uiStore);

function closeReplayModal() {
  replayModalUrl.value = null;
}
</script>
